<template>
  <div class="page-classes bg-light">
    <div id="classes-feature" class="feature bg-classes">
      <h1 class="feature-lead py-5">CLASSES</h1>
    </div>
    <div
      class="container-lg mx-auto px-0 pt-0 pt-md-5 pb-5 d-flex justify-content-between align-items-start position-relative"
    >
      <sidebar-left class="user-select-none" :class="{ expand: expandSidebar }">
        <h6
          class="mb-5 text-muted d-flex justify-content-between align-items-center"
        >
          <span>
            <i class="fas fa-filter me-2" />
            Filter
          </span>
          <button
            class="d-md-none btn btn-sm btn-primary text-decoration-none fw-medium px-4 rounded-pill"
            @click="expandSidebar = false"
          >
            Apply
          </button>
        </h6>
        <div>
          <p class="fw-medium mb-2">Pricing</p>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              id="filterTypeAll"
              value="all"
              v-model="filters.type"
            />
            <label class="form-check-label" for="filterTypeAll">All</label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              id="filterTypeFree"
              value="free"
              v-model="filters.type"
            />
            <label class="form-check-label" for="filterTypeFree">Free</label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              id="filterTypePaid"
              value="paid"
              v-model="filters.type"
            />
            <label class="form-check-label" for="filterTypePaid">Paid</label>
          </div>
        </div>
        <div class="mt-4">
          <p class="fw-medium mb-2">Availability</p>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              id="filterAvailabilityAll"
              value="all"
              v-model="filters.availability"
            />
            <label class="form-check-label" for="filterAvailabilityAll">
              All
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              id="filterAvailabilityOngoing"
              value="ongoing"
              v-model="filters.availability"
            />
            <label class="form-check-label" for="filterAvailabilityOngoing">
              Ongoing
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              id="filterAvailabilityUpcoming"
              value="upcoming"
              v-model="filters.availability"
            />
            <label class="form-check-label" for="filterAvailabilityUpcoming">
              Paid
            </label>
          </div>
        </div>
        <div class="mt-4">
          <p class="fw-medium mb-2">Class Level</p>
          <div
            class="form-check"
            v-for="level in classLevel"
            :key="`filterLevel${level}`"
          >
            <input
              class="form-check-input"
              type="checkbox"
              :id="`filterLevel${level}`"
              :value="level"
              v-model="filters.level"
            />
            <label class="form-check-label" :for="`filterLevel${level}`">
              {{ level }}
            </label>
          </div>
        </div>
      </sidebar-left>
      <div
        class="row row-cols-2 row-cols-lg-3 row-cols-xl-4 px-3 g-2 mx-0 flex-fill"
      >
        <div class="sidebar-toggle col-12 d-md-none py-3 sticky-top bg-light">
          <button
            class="btn btn-primary w-100 py-3"
            @click="expandSidebar = true"
          >
            <i class="fas fa-filter me-2" />
            Filter
          </button>
        </div>
        <template v-if="!isLoading && classes.length > 0">
          <div class="col" v-for="cls in classes" :key="cls._id">
            <div class="card rounded-card h-100 shadow-sm">
              <div
                class="card-tag badge py-2 px-3"
                :class="`bg-${Helper.getLevelColor(cls.level)}`"
                v-if="cls.level"
              >
                {{ cls.level }}
              </div>
              <div
                class="card-image ratio ratio-16x9 bg-light shadow-bottom"
                :class="{
                  'clickable cursor-zoom': Helper.formatMediaUrl(cls.poster),
                }"
                @click="currentMedia = cls.poster"
              >
                <img
                  :src="Helper.formatMediaUrl(cls.poster)"
                  v-if="cls.poster"
                />
                <img src="@/assets/images/poster-default.jpg" v-else />
              </div>
              <div class="card-body py-4">
                <p
                  class="fw-medium card-title mb-4 lh-sm"
                  v-html="cls.name"
                ></p>
                <ul class="list-group">
                  <li
                    class="list-group-item text-muted border-0 py-0 px-0"
                    v-for="(note, index) in cls.note
                      ? cls.note.split('<br />')
                      : []"
                    :key="`note${index}`"
                  >
                    {{ note }}
                  </li>
                </ul>
              </div>
              <div
                class="card-footer bg-white py-3 d-flex flex-column flex-sm-row justify-content-between align-items-center text-nowrap"
              >
                <span class="text-primary fw-medium text-nowrap mb-2 mb-sm-0">
                  {{ cls.price > 0.0 ? Helper.formatMoney(cls.price) : "Free" }}
                </span>
                <a
                  class="btn bg-link btn-sm px-3 pe-none fw-medium text-muted text-nowrap lh-1 small py-0"
                  href="#"
                  v-if="!cls.link"
                >
                  COMING<br />
                  SOON
                </a>
                <template v-else>
                  <a
                    class="btn bg-primary btn-sm-responsive btn-sm px-3 clickable text-light text-nowrap"
                    :href="cls.link"
                    target="_blank"
                  >
                    <template v-if="cls.price == 0.0">
                      <i class="fas fa-gift me-2" />Claim
                    </template>
                    <template v-else>
                      <i class="fas fa-money-check-alt me-2" />Buy
                    </template>
                  </a>
                </template>
              </div>
            </div>
          </div>
        </template>
        <div
          v-if="!isLoading && classes.length < 1"
          class="col-12 py-5 text-center text-muted bg-white shadow-sm fst-italic"
        >
          No class available
        </div>
        <loading-spinner
          class="position-relative bg-light text-muted"
          style="min-height: 250px"
          v-else-if="isLoading"
        >
          Loading
        </loading-spinner>
      </div>
    </div>
    <media-viewer
      v-if="currentMedia"
      :url="this.Helper.formatMediaUrl(currentMedia)"
      @close="currentMedia = null"
    />
  </div>
</template>

<script>
import LoadingSpinner from "@/components/LoadingSpinner";
import SidebarLeft from "@/components/SidebarLeft";
import MediaViewer from "@/components/Modals/MediaViewer";

export default {
  components: {
    LoadingSpinner,
    SidebarLeft,
    MediaViewer,
  },
  data() {
    return {
      isLoading: false,
      classes: [],
      currentMedia: false,
      expandSidebar: false,
      classLevel: ["Beginner", "Intermediate", "Advance"],
      filters: {
        type: ["all"],
        availability: ["all"],
        level: [],
      },
    };
  },
  watch: {
    expandSidebar: {
      handler() {
        if (this.expandSidebar) {
          document.body.classList.add("overflow-hidden", "overflow-hidden-md");
        } else {
          document.body.classList.remove(
            "overflow-hidden",
            "overflow-hidden-md"
          );

          this.$nextTick(() => {
            window.scrollTo(
              0,
              document.getElementById("classes-feature").clientHeight -
                document.getElementById("main-navbar").clientHeight
            );
          });
        }
      },
      immediate: true,
    },
    filters: {
      handler() {
        if (this.filters.type.length > 1) {
          this.filters.type.shift();
        } else if (this.filters.type.length == 0) {
          this.filters.type = ["all"];
        }

        if (this.filters.availability.length > 1) {
          this.filters.availability.shift();
        } else if (this.filters.availability.length == 0) {
          this.filters.availability = ["all"];
        }

        this.$nextTick(() => {
          this.getClasses();
        });
      },
      deep: true,
    },
  },
  methods: {
    goToClass(cls) {
      const user = this.$store.state.user;

      if (user) {
        window.open(cls.link, "_blank");
      } else {
        this.Helper.showLoginPrompt(this.$route.path);
      }
    },
    getClasses() {
      this.isLoading = true;

      let url = "classes?_limit=-1&status=Published";

      let filterType = this.filters.type[0];
      let filterAvailability = this.filters.availability[0];
      let filterLevel = this.filters.level;

      if (this.filters.type.length > 0 && filterType != "all") {
        url += `${filterType == "free" ? "&price=0.00" : "&price_gt=0.00"}`;
      }

      if (this.filters.availability.length > 0 && filterAvailability != "all") {
        url += `${
          filterAvailability == "ongoing"
            ? "&link_null=false"
            : "&link_null=true"
        }`;
      }

      if (filterLevel.length > 0 && filterLevel.length < 3) {
        let levelQuery = filterLevel.map((fl) => `&level_in=${fl}`);

        url += levelQuery.join("");
      }

      this.API.get(url)
        .then((retVal) => {
          this.classes = retVal.data.sort((a, b) => {
            if (a.price < b.price) return -1;
            if (a.price > b.price) return 1;

            if (a.name > b.name) return 1;
            if (a.name < b.name) return -1;
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.getClasses();
  },
};
</script>